import { Mesh, SphereGeometry,MeshLambertMaterial,Object3D, Group, Color, MeshBasicMaterial, MeshStandardMaterial, DirectionalLight, SpotLight, HemisphereLight, PointLight, AmbientLight } from 'three';

import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare.js';
import { Sky } from "three/examples/jsm/objects/Sky";
import axios from 'axios';
import mainConfig from "../../main.config";
import Utility from '@/utils';
import { Season } from '@/Enum/Enums';

import flare1 from '../models/textures/flares/lensflare0.png';
import flare2 from '../models/textures/flares/lensflare3.png';

const flares = [
  {
    name : "flare1",
    url: flare1
  },
  {
    name : "flare2",
    url: flare2
  }
]

class Sonne {
  radius = 40;
  sunsetCenter = .5;
  offset = {
    x: 0,
    y: -5,
    z: 0
  }
  currentDegree = 0;

  sunLight = null;
  sunLight = null;
  sunLightContainer = null;
  sun = null;

  constructor(store, xr) {

    this.store = store;
    this.xr = xr;
    this.seasonValues = {
      Spring: {
        angle: 0.5,
      },
      Summer: {
        angle: -0.2,
      },
      Autumn: {
        angle: 0.5,
      },
      Winter: {
        angle: 1.0,
      }
    };
    this.sunColor = {
      dayColor: 0xffffff,
      nightColor: 0xff0000
    }
   
    const geometry = new SphereGeometry(5, 32, 32);
    const material = new MeshLambertMaterial({ 
      color: 0xf38d2f , 
      emissive : 0xf38d2f
    });
    this.sphere = new Group();

    this.xr.CustomTextureLoader.loadStack({
      stack: flares,
      progress : ()=>{}
    }).then(flareStack=>{

      
      this.lensflare = new Lensflare();
      this.lensflare.addElement( new LensflareElement( flareStack.flare2.texture, 700, 0, new Color(0xf38d2f) ) );
      //this.lensflare.addElement( new LensflareElement( flareStack.flare1.texture, 60, 0.6 ) );
      // this.lensflare.addElement( new LensflareElement( flareStack.flare2.texture, 70, 0.7 ) );
      // this.lensflare.addElement( new LensflareElement( flareStack.flare2.texture, 120, 0.9 ) );
      // this.lensflare.addElement( new LensflareElement( flareStack.flare2.texture, 70, 1 ) );
      this.sphere.add( this.lensflare );
    });
    


    //Sonne
    this.sunLight = new DirectionalLight(this.sunColor.dayColor, 1);
    this.sunLight.castShadow = true;
    //Set up shadow properties for the light
    this.sunLight.shadow.mapSize.width = 1024; // default
    this.sunLight.shadow.mapSize.height = 1024; // default
    this.sunLight.shadow.camera.near = .001; // default
    this.sunLight.shadow.camera.far = 70; // default
    this.sunLight.shadow.bias = -0.005; // default

    const d = 20;

    this.sunLight.shadow.camera.left = - d;
    this.sunLight.shadow.camera.right = d;
    this.sunLight.shadow.camera.top = d;
    this.sunLight.shadow.camera.bottom = - d;
    this.sunLight.target.position.set(0, 0, 0);
    this.sunLight.add(this.sphere);

    //LightContainer
    this.sunLightContainer = new Object3D();
    this.sunLightContainer.add(this.sunLight);
    
    //AmbientLight
    this.ambientLight = new AmbientLight(this.seasonValues[this.store.state.area.season].shadowColorDay, 0);//0x0d47a1
    this.sunLightContainer.add(this.ambientLight);

    this.sunLightContainer.rotation.x = 0.9

    //Hemilight
    this.hemiLight = new HemisphereLight(0x000000, 0x000000, 1);
    this.sunLightContainer.add(this.hemiLight);

    this.sun = new Group();

    //this.sun.add(this.sky);
    this.sun.add(this.sunLightContainer);

    this.store.watch(state => state.area.sunPosition,this.UpdateSunValues);
    this.store.watch(state => state.content.currentViewContent,this.UpdateSunValues);
    this.store.watch(state => state.content.currentSubTab,this.UpdateSunValues);
    this.store.watch(state => state.content.currentViewContent,this.UpdateSunValues);
    this.store.watch(state => state.area.season,this.UpdateSunValues);
    
    this.SetSun(this.store.state.area.sunPosition);

    return this;
  }

  UpdateSunValues = () => {
    switch(this.store.state.area.season){
      case Season.Spring:
        this.sunsetCenter = .5;
      break;
      case Season.Summer:
        this.sunsetCenter = .75;
        break;
      case Season.Autumn:
        this.sunsetCenter = .5;
        break;
      case Season.Winter:
        this.sunsetCenter = .35;
        break;
    }


    this.SetSun(this.store.state.area.sunPosition);

    if(this.store.state.content.currentViewContent != null){
      //this.SetSunAngle(this.store.state.content.currentViewContent.SunAngle);
    }
  }

  getPointOnCircle(radius, deg) {
    var x = this.offset.x + radius * Math.cos(deg);
    var y = this.offset.y + radius * Math.sin(deg);
    var z = this.offset.z;

    return {
      x: x,
      y: y,
      z: z
    }
  }

  SetSun(sunVal) {
    
    //sunVal: 0 -> 1 
    sunVal = (sunVal + 0.5) % 1;
    var deg = Math.abs(sunVal) * Math.PI * 2 - Math.PI * 3 / 2;
    var { x, y, z } = this.getPointOnCircle(this.radius, deg);

    this.sunLight.position.set(x, y, z);
    this.sunLight.target.position.set(0, 0, 0);

    if( this.store.state.content.currentViewContent != null && this.store.state.content.currentViewContent.hasOwnProperty("SeasonLightSetting")){
      this.SetLights(this.store.state.content.currentViewContent.SeasonLightSetting);
    }
  }

  SetSunAngle = (angle)=>{
    //this.sunLightContainer.rotation.x = (angle);
  }

  SetLights(SeasonLightSetting){
    var lightValues = SeasonLightSetting.find( setting => setting.Season === this.store.state.area.season);

    
    if(typeof(lightValues) === "undefined" || 
    lightValues.DayLightSetting == null ||
    lightValues.SunsetLightSetting == null ||
    lightValues.NightLightSetting == null
    ){return;}

    //console.log(lightValues.DayLightSetting,lightValues.SunsetLightSetting, lightValues.NightLightSetting)

    Object.keys(lightValues.DayLightSetting).map((settingKey)=>{ lightValues.DayLightSetting[settingKey] = isNaN(parseFloat(lightValues.DayLightSetting[settingKey])) ? lightValues.DayLightSetting[settingKey] : parseFloat(lightValues.DayLightSetting[settingKey] )   });
    Object.keys(lightValues.SunsetLightSetting).map((settingKey)=>{ lightValues.SunsetLightSetting[settingKey] = isNaN(parseFloat(lightValues.SunsetLightSetting[settingKey])) ? lightValues.SunsetLightSetting[settingKey] : parseFloat(lightValues.SunsetLightSetting[settingKey] )   });
    Object.keys(lightValues.NightLightSetting).map((settingKey)=>{ lightValues.NightLightSetting[settingKey] = isNaN(parseFloat(lightValues.NightLightSetting[settingKey])) ? lightValues.NightLightSetting[settingKey] : parseFloat(lightValues.NightLightSetting[settingKey] )   });
    //SUN
    
    var lerpAlpha = this.store.state.area.sunLerpPosition < this.sunsetCenter ? 
      Utility.Remap(this.store.state.area.sunLerpPosition, 0, this.sunsetCenter, 0, 1) : 
      Utility.Remap(this.store.state.area.sunLerpPosition, this.sunsetCenter, 1 , 0, 1);
    
    var lerpValues = {
      start : Object.assign({}, lightValues.DayLightSetting),
      end : Object.assign({}, lightValues.SunsetLightSetting),
    }

    if(this.store.state.area.sunLerpPosition < this.sunsetCenter){
      lerpValues.start = Object.assign({}, lightValues.DayLightSetting);
      lerpValues.end = Object.assign({}, lightValues.SunsetLightSetting);
    }else{
      lerpValues.start = Object.assign({}, lightValues.SunsetLightSetting);
      lerpValues.end = Object.assign({}, lightValues.NightLightSetting);
    }

   // console.log("Sun", lightValues,  this.store.state.area.sunLerpPosition , Utility.lerp(lightValues.DayLightSetting.SunIntensity,lightValues.NightLightSetting.SunIntensity,  this.store.state.area.sunLerpPosition ) )
    this.sunLight.intensity = Utility.lerp(lerpValues.start.SunIntensity,lerpValues.end.SunIntensity,  lerpAlpha );
    this.sunLight.color = new Color( Utility.lerpColor(lerpValues.start.SunColor, lerpValues.end.SunColor, lerpAlpha) );
    
    //Ambient
    this.ambientLight.intensity = Utility.lerp(lerpValues.start.AmbientIntensity,lerpValues.end.AmbientIntensity,  lerpAlpha );
    this.ambientLight.color = new Color( Utility.lerpColor(lerpValues.start.AmbientColor, lerpValues.end.AmbientColor, lerpAlpha) );
    
    //HemiLight
    this.hemiLight.intensity = Utility.lerp(lerpValues.start.HemiIntensity,lerpValues.end.HemiIntensity,  this.store.state.area.sunLerpPosition );
    this.hemiLight.groundColor = new Color( Utility.lerpColor(lerpValues.start.HemiLightFloorColor, lerpValues.end.HemiLightFloorColor, lerpAlpha) ); 
    this.hemiLight.color =  new Color( Utility.lerpColor(lerpValues.start.HemiLightSkyColor, lerpValues.end.HemiLightSkyColor, lerpAlpha) );      



  }

  GetColorValue(a, b, amount) {

    var ah = +a.toString().replace('#', '0x'),
      ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
      bh = +b.toString().replace('#', '0x'),
      br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
      rr = ar + amount * (br - ar),
      rg = ag + amount * (bg - ag),
      rb = ab + amount * (bb - ab);

    return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
  }
}



export default Sonne;