import {HTMLMesh} from 'three/examples/jsm/interactive/HTMLMesh'
import {InteractiveGroup} from 'three/examples/jsm/interactive/InteractiveGroup';
import ScreenConstants from '../ScreenComponents/ScreenConstants';
import ThreeMeshUI from "three-mesh-ui";
import {Color, Vector3} from 'three';
import FontBoldImage from '../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../Fonts/Roboto-Bold-msdf.json';

import FontImage from '../../Fonts/Roboto-msdf.png'
import FontJSON from '../../Fonts/Roboto-msdf.json';
import ScreenUtils from '../ScreenComponents/ScreenUtils';


class TinyCityController {
  xr;
  store;
  router;
  constructor({xr, store, router}) {
    this.xr = xr;
    this.store = store;
    this.router = router;
    this.buttons = [];
    this.initialized = false;

    this.store.watch(state => state.area.areaCMSLoaded, ()=>this.Init()  );
    this.store.watch(state => state.xrMode, ()=>this.Init() );
    this.xr.Events.addEventListener("OnAnimationLoop", this.Update);


    this.store.watch(state => state.viewMode , ()=>this.SetVisiblity())
  }

  Init() {

    console.log("watch", this.store.state.area.areaCMSLoaded , this.store.state.xrMode);
    if (!this.initialized && this.store.state.area.areaCMSLoaded && this.store.state.xrMode == "VR") {

      console.log("watch");

      Object.values(this.store.state.area.areas).map((area) => {
        var btn = this.CreateButton(area);

        this.xr.SceneController.AddToScene("UI", btn);

        this.buttons.push(btn);

      });

      this.initialized = true;
    }
  }

  CreateButton(area) {
    const container = new ThreeMeshUI.Block({
      width: 10,
      height: 2.3,
      padding: .25,
      backgroundOpacity: 1,
      backgroundColor: ScreenConstants.white,
      borderColor: ScreenConstants.orange,
      borderRadius: .25,
      borderWidth: .05
    });
    container.name = "TextButton";
    container.isClickEnabled = true;
    container.setupState({
      state: "idle",
      attributes: {
        backgroundColor: ScreenConstants.white
      }
    });
    container.setupState({
      state: "hovered",
      attributes: {
        backgroundColor: ScreenConstants.lightGray
      }
    });
    container.setupState({
      state: "selected",
      attributes: {
        backgroundColor: ScreenConstants.orange
      },
      onSet: () => {
        this.store.state.audio.controller.PlaySound("menu.click", false);

        this.router.push('/VR/' + area.cmsData.routeParam + (area.name != "Apartment" ? "/configurator" : "/explore")).catch(()=>{});

      }
    });
    this.xr.Controls.ActiveObjects.push(container);


    //
    const titleContainer = new ThreeMeshUI.Block({
      width: 9.5,
      height: .75,
      fontSize: .7,
      alignContent: "left",
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontColor: ScreenConstants.orange,
      backgroundOpacity: 0
    });
    const text = new ThreeMeshUI.Text({content: area.cmsData.Title});
    titleContainer.add(text);

    container.add(titleContainer);

    const descriptionContainer = new ThreeMeshUI.Block({
      width: 9.5,
      height: 1.5,
      fontSize: .5,
      alignContent: "left",
      fontColor: ScreenConstants.darkGrayFont,
      fontFamily: FontJSON,
      fontTexture: FontImage,
      backgroundOpacity: 0
    });

    descriptionContainer.add(new ThreeMeshUI.Text({content: area.cmsData.Description}));
    container.add(descriptionContainer);

    container.position.copy(new Vector3(area.cmsData.TinyCityVR_Position.x, area.cmsData.TinyCityVR_Position.y, area.cmsData.TinyCityVR_Position.z));
    return container;
  }

  SetVisiblity(){
    if(this.router.currentRoute.params.xrMode != "VR"){return;}

    var visible = this.store.state.viewMode == "tinyCity";

      this.buttons.map((btn)=>{
        ScreenUtils.SetClickEnabled(btn, visible);
        btn.visible = visible;
      })
  
  }


  CreatePost(post) {}

  Update = () => {
    if (this.router.currentRoute.name != "TinyCity" || this.router.currentRoute.params.xrMode != "VR") {
      return;
    }

    this.buttons.map(btn => {
      var camPos = this.xr.Controls.GetCameraPosition();
      var btnPosition = btn.position;

      const d = btnPosition.distanceTo(camPos);
      const scale = 1 + (d * d * .00025);
      btn.scale.set(scale, scale, scale);
      btn.lookAt(camPos);
    })
  }

}

export default TinyCityController;
