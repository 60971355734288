import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import SprungmarkenIcon from './SprungmarkenIcon';
import Vue from 'vue';

import Strommast from "../../SVG/Article/DGSZentrum_IconSet_Netz_Icon";
import Batterie from "../../SVG/Article/DGSZentrum_IconSet_PvGeräte_Icon";
//import EAuto from "../../SVG/Configurator/DGSZentrum_IconSet_EAutoTrue_Icon";
import Solarmodul from "../../SVG/Article/DGSZentrum_IconSet_PvModule_Icon";
import ModulmontageIcon from "../../SVG/Article/DGSZentrum_IconSet_Modulmontage_icon";


import Verschattung from "../../SVG/Article/DGSZentrum_IconSet_Shadow_Icon";
import Kueche from "../../SVG/Article/DGSZentrum_IconSet_Alltagsverbrauch_Icon";
import { SVGIcon } from "../../Enum/Enums";

import EAuto from '../../SVG/Article/DGSZentrum_IconSet_EAutoTrue_Icon';
import EAutoFalse from '../../SVG/Configurator/DGSZentrum_IconSet_EAutoFalse_Icon';

import Ausrichtung from "../../SVG/Article/DGSZentrum_IconSet_Kompass_Icon";
import PumpIcon from "../../SVG/Configurator/DGSZentrum_IconSet_PumpTrue";
import MesskonzeptIcon from '../../SVG/Article/DGSZentrum_IconSet_MesskonzeptIcon_Icon';
import SolarpflichtIcon from '../../SVG/Article/DGSZentrum_IconSet_Solarpflicht_Icon';
import EigentumIcon from '../../SVG/Article/DGSZentrum_IconSet_Eigentum_Icon';
import BeteiligungsIcon from '../../SVG/Article/DGSZentrum_IconSet_Beteiligungsquote_Icon';


import { Vector3 } from 'three';
import BetreiberIcon from '@/SVG/Article/DGSZentrum_IconSet_Betreiberkonzept_Icon.vue';
import ArrowRightVue from '@/SVG/Configurator/Arrow_right.vue';

import ExplorerIcon from "@/SVG/Mainmenu/ExplorerIcon.vue";
import BalkonIcon from '@/SVG/Article/DGSZentrum_IconSet_Balkon_Icon.vue';
import BetriebsstromIcon from '@/SVG/Article/DGSZentrum_IconSet_Betriebsstrom_Icon.vue';
import AlltagsverbrauchIcon from '@/SVG/Article/DGSZentrum_IconSet_Alltagsverbrauch_Icon.vue';
import ModulmontagebalkonIcon from '@/SVG/Article/DGSZentrum_IconSet_Modulmontagebalkon_icon.vue';
import MieterstrominitiierenIcon from '@/SVG/Article/DGSZentrum_IconSet_MieterstromInitiieren_Icon.vue';


class SprungMarkenController {
  constructor(props) {
    this.xr = props.xr;

    this.store = props.store;
    this.router = props.router;
    this.routeName = null;
    this.marks = [];
    this.roomMarks = {};



    this.markSVGIcons = {
      autarkiegrad: Strommast,
      module: ModulmontageIcon,
      wechselrichter: Batterie,
      car: EAuto,
      shading: Verschattung,
      kitchen: Kueche,
      direction: Ausrichtung,
      pv: Solarmodul,
      pump: PumpIcon,
      FamilyHouse: PumpIcon,
      Apartment: PumpIcon,
      ApartmentBuilding: PumpIcon,
      livingroom : AlltagsverbrauchIcon,
      hallway : BetriebsstromIcon,
      balcony : BalkonIcon,
      messkonzept : MesskonzeptIcon,
      modulmontagemieterstrom : ModulmontageIcon,
      ModulmontageBalkon : ModulmontagebalkonIcon,
      AutarkiegradMieterstrom : Strommast,
      solarpflicht : SolarpflichtIcon,
      eigentum : EigentumIcon,
      beteiligung : BeteiligungsIcon,
      betreiber : BetreiberIcon,
      verschattung : Verschattung,
      ArrowRight : ArrowRightVue,
      explorer : ExplorerIcon,
      pumpmieterstrom : PumpIcon,
      technikraum: Batterie,
      mieterstrominitiieren: MieterstrominitiierenIcon
    };

    
    this.icon = document.getElementById("poi-svg-icon");
    const mustUpdateRoutes = ["ExplorerSingle","TinyCity"];

    this.mustUpdate = (["dashboard","tinyCity","configurator"]).includes(this.store.state.viewMode);


    this.router.beforeEach((to, from, next) => {
      this.routeName = to.name;
      //if (!this.store.state.world.sceneReady) { next(); return; }

      this.mustUpdate = mustUpdateRoutes.includes(this.routeName);

      if (this.routeName != "ExplorerSingle" && this.routeName != "Explorer") {
        this.RemoveAll();
      }
      else if (this.routeName == "Explorer") {
        this.SetVisible(false)
      }else if(this.routeName == "ExplorerSingle"){
        this.SetVisible(true)
      }

      next();
    });

    this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);
  }

  RemoveAll() {
    this.marks.forEach((mark) => {
      this.xr.CSSSceneController.RemoveObj(mark);
    })

  }

  SetVisible = (visible) => {
    this.marks.forEach((mark) => {
      mark.visible = visible
    })
  }

  SetNewMarks(markName, markObjects, scaleWithDistance = false, useIcons = true, useLink = false) {

    return this.roomMarks[markName] = markObjects.map((mark) => {

      if(useIcons){

        mark.IconName = mark.IconName == "eauto" ? "car" : mark.IconName;

        console.log("UseIcon", markName, mark.IconName , mark.Name);

        const Icon = Vue.extend(this.markSVGIcons[mark.IconName]);

        const IconElement = new Icon().$mount().$el;
        return this.createPlane(mark, IconElement, scaleWithDistance,useLink)
      }else{
        return this.createPlane(mark, null, scaleWithDistance,useLink)

      }
    });
    //this.xr.CSSSceneController.RemoveAll();

    
  }

  createPlane(mark, icon, scaleWithDistance = false,useLink = false) {
    var cssClass = 'poi-button';
    if (mark.css != undefined) {
      cssClass = mark.css
    }

    const element = document.createElement('button');

    element.addEventListener("click", e => {
      this.store.state.audio.controller.PlaySound("menu.click");
      this.ChangePOI(mark.Route);
    });
    element.classList.add(cssClass);
    element.userData = {
      name : mark.IconName
    };


    var container = document.createElement("div");
    container.classList.add("mark-text-container")

    if(icon != null){
      element.appendChild(icon);
    }

    if(useLink){
    var text = document.createElement("h2");
        text.innerHTML = mark.Name;
      container.appendChild(text);
    }

    if (mark.SubText != undefined) {
      var subText = document.createElement("p");
      subText.innerHTML = mark.SubText;
      container.appendChild(subText);


      if(useLink){
        const Arrow = Vue.extend(this.markSVGIcons.ArrowRight);

        const arrowElement = new Arrow().$mount().$el;

        subText.appendChild(arrowElement);
      }

    }

    element.appendChild(container);

    const object = new CSS3DObject(element);

    console.log("CreatePlane" , mark.Name , mark ,mark.Pos);

    object.position.copy(mark.Pos);


    this.xr.CSSSceneController.AddToScene(mark.Room, object);

    this.marks.push(object);

    object.userData.scaleWithDistance = scaleWithDistance;

    return object;
  }

  ChangePOI(route) {
    
    this.router.push("/desktop/" + route).catch(() => {});
  }

  Animate = () => {

    if(!this.mustUpdate){return;}

    this.marks.forEach((mark) => {
      const pos = mark.position.clone();
      const lookat = this.xr.Controls.GetPosition();
      //Zur Camera drehen
      if(typeof(this.xr.Scene._target) != "undefined"){
        mark.position.copy(this.xr.Scene._target);
      }else{
        mark.position.set(0,0,0);
      }

      mark.lookAt(lookat);
      mark.position.set(pos.x,pos.y,pos.z);
      mark.scale.set(1.5,1.5,1.5);

      if(mark.userData.scaleWithDistance){
        const distance = lookat.distanceTo(mark.position);
        const distanceFactor = distance * .0006;
        mark.scale.set(distanceFactor,distanceFactor,distanceFactor);
      }
    });
  }
}

export default SprungMarkenController;