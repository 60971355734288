import mainConfig from '../../../main.config';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';

class FunfactController {
  constructor(props) {
    this.xr = props.xr;

    this.store = props.store;
    this.router = props.router;
    this.routeName = null;
    this.marks = [];

    this.icon = document.getElementById("poi-svg-icon");

    this.router.beforeEach((to, from, next) => {
      this.routeName = to.name;
      //if (!this.store.state.world.sceneReady) { next(); return; }

      if (this.routeName != 'TinyCity') {
        this.RemoveAll();
      }

      next();
    });

    this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);
  }

  RemoveAll() {
    this.marks.forEach((mark) => {
      this.xr.CSSSceneController.RemoveObj(mark);
    })

  }

  SetVisible(visible) {
    this.marks.forEach((mark) => {
      mark.visible = visible
    })
  }

  SetNewMarks(markObjects, scaleWithDistance = false) {
    //this.xr.CSSSceneController.RemoveAll();

    let marks = markObjects.map((mark) => {
      return this.createPlane(mark, scaleWithDistance);
    });

    return marks.map(obj =>{
      obj.container.addEventListener("click", ()=>{
        
        this.store.state.audio.controller.PlaySound("menu.click");

        if(obj.container.classList.contains("visible")){
          obj.container.classList.remove("visible");      

         
        }else{
          
          marks.map((others)=>{
            if(others.container != obj.container){
              others.container.classList.remove("visible");  
            }
          });
          obj.container.classList.add("visible");
            
        }
      });

      return obj.object;
    });

  
  }

  createPlane(mark, scaleWithDistance = false) {
    var cssClass = 'fun-fact';
    const element = document.createElement('div');
    element.classList.add(cssClass);
    
    var container = document.createElement("div");
    container.classList.add("funfact-container")

    var text = document.createElement("h2");
        text.innerHTML = mark.Title;
        
        container.appendChild(text);


    var subText = document.createElement("p");
    subText.innerHTML = mark.description;    
    container.appendChild(subText);


    


    if(mark.image != null){
      var img = document.createElement("img");
      img.src= mainConfig.CMS_BASE_URL + mark.image.url;
      container.appendChild(img);
    }
    
    element.appendChild(container);

    const object = new CSS3DObject(element);

    object.position.set(mark.position.x,mark.position.y,mark.position.z);


    this.xr.CSSSceneController.AddToScene("TinyCity", object);

    this.marks.push(object);

    object.userData.scaleWithDistance = scaleWithDistance;

    return {object : object, container:container};
  }


  Animate = () => {
    this.marks.forEach((mark) => {
      const pos = mark.position.clone();
      const lookat = this.xr.Controls.GetPosition();
      //Zur Camera drehen
      if(typeof(this.xr.Scene._target) != "undefined"){
        mark.position.copy(this.xr.Scene._target);
      }else{
        mark.position.set(0,0,0);
      }

      mark.lookAt(lookat);
      mark.position.set(pos.x,pos.y,pos.z);

      if(mark.userData.scaleWithDistance){
        const distance = lookat.distanceTo(mark.position);
        const distanceFactor = distance * .0006;
        mark.scale.set(distanceFactor,distanceFactor,distanceFactor);
      }
    });
  }
}

export default FunfactController;